import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'
import OrderList from '../views/OrderList.vue'
import CompanyList from '../views/CompanyList.vue'
import Settings from '../views/Settings.vue'
import InProgress from '../views/InProgress.vue'
import Help from '../views/Help.vue'
import ClusteringDrawingHexagon from '../views/ClusteringDrawingHexagon'
import CompleteTransportPlanning from '../views/CompleteTransportPlanning'
import Notifications from '../views/Notifications'
import manualOrder from '../views/manualOrder'
import pendingOrdersTableManual from '../components/OrderViewComponents/pendingOrdersTableManual'
import ImageViewer  from '../views/ImageViewer'

Vue.use(VueRouter)



const routes = [{
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
            auth: true
        }
    },
    {
        path: '/orderlist',
        name: 'OrderList',
        component: OrderList,
        meta: {
            auth: true
        }
    },
    {
        path: '/progress',
        name: 'InProgress',
        component: InProgress,
        meta: {
            auth: true
        }
    },
    {
        path: '/add-order',
        name: 'AddOrder',
        component: manualOrder,
    },
    {
        path: '/clustering',
        name: 'ClusteringDrawingHexagon',
        component: ClusteringDrawingHexagon
    },
    {
        path: '/completeplanning',
        name: 'CompleteTransportPlanning',
        component: CompleteTransportPlanning
    },
    {
        path: '/companylist',
        name: 'CompanyList',
        component: CompanyList
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings
    },
    {
        path: '/help',
        name: 'Help',
        component: Help
    },
    {
        path: '/notifications',
        name: 'Notifications',
        component: Notifications
    },
    {
        path: '*',
        name: 'Not Found',
        component: NotFound
    },
    {
        path: '/temp',
        name: 'Temp',
        component: pendingOrdersTableManual
    },
    {
        path: '/image',
        name: 'Image',
        component: ImageViewer
    },
    // {
    //     path: '/allocation',
    //     beforeEnter() { location.href = 'https://logichainge-allocation.ew.r.appspot.com/get/trips' }
    // }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router