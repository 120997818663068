<template>
    <div>
        <h1>Transport file: {{ this.transportData.id }}</h1>
        <h3>General information</h3>
        <div class="main-inputs">
            <div class="input-label">
                <label for="client">Client</label>
                <select name="" id="client-name">
                    <option v-for="name in this.transportData.client.name" :key="name" :value="name">{{ name }}
                    </option>
                    <option value="N/A">N/A</option>
                </select>
                <input type="text" id="client-name-input" class="custom-input">
                <v-icon medium color="primary" class="open-button"
                    @click="toggleCustomInput('client-name')">mdi-pencil-box</v-icon>
            </div>
            <div class="input-label">
                <label for="contact">Contact</label>
                <select name="" id="contact-email">
                    <option v-for="email in this.transportData.contact.email" :key="email" :value="email">{{
                        email
                    }}
                    </option>
                    <option value="N/A">N/A</option>
                </select>
                <input type="text" id="contact-email-input" class="custom-input">
                <v-icon medium color="primary" class="open-button"
                    @click="toggleCustomInput('contact-email')">mdi-pencil-box</v-icon>
            </div>
            <div class="input-label">
                <label for="department">Department</label>
                <input type="text" v-bind:value="this.transportData.department.name" id="dep-name">
            </div>
            <div class="input-label">
                <label for="reference">Reference</label>
                <input type="text" v-bind:value="this.transportData.invoice_reference" id="inv-ref">
            </div>
            <div class="input-label">
                <label for="service-level">Service level</label>
                <select name="service-level" id="service-level">
                    <option :value="this.transportData.service_level" selected>{{
                        this.transportData.service_level
                    }}
                    </option>
                    <option v-for="serviceLevel in serviceLevels" v-bind:key="serviceLevel.ID" :value="serviceLevel.ID">
                        {{ serviceLevel.Description }}</option>
                    <option value="N/A">N/A</option>
                </select>
            </div>
            <div class="input-label">
                <label for="employee">Employee</label>
                <input type="text" v-bind:value="this.transportData.employee.name" id="employee">
            </div>
            <div class="input-label">
                <label for="file-type">File type</label>
                <select name="file-type" id="file-type">
                    <option :value="this.transportData.file_type">{{ this.transportData.file_type }}</option>
                    <option v-for="fileType in fileTypes" v-bind:key="fileType.ID" :value="fileType.ID">{{
                        fileType.Description
                    }}</option>
                    <option value="N/A">N/A</option>
                </select>
            </div>
            <div class="input-label">
                <label for="modality">Modality</label>
                <select name="modality" id="modality">
                    <option :value="this.transportData.modality">{{ this.transportData.modality }}</option>
                    <option v-for="modality in modalities" v-bind:key="modality.ID" :value="modality.ID">{{
                        modality.Description
                    }}</option>
                    <option value="N/A">N/A</option>
                </select>
            </div>
            <div class="input-label">
                <label for="costcode">Cost code</label>
                <input type="text" v-bind:value="this.transportData.cost_code" id="costcode">
            </div>
            <div class="input-label">
                <label for="equipment">Equipment</label>
                <input type="text" v-bind:value="this.transportData.equipment_type" id="equipment">
            </div>
            <div class="input-label">
                <label for="incoterms">Incoterm</label>
                <select name="incoterms" id="incoterms">
                    <option :value="this.transportData.incoterms">{{ this.transportData.incoterms }}</option>
                    <option v-for="incoterm in incoterms" v-bind:key="incoterm.ID" :value="incoterm.ID">{{
                        incoterm.Description
                    }}</option>
                    <option value="N/A">N/A</option>
                </select>
            </div>
            <div class="input-label">
                <label for="invoice-group">Customs</label>
                <input type="text" v-bind:value="this.transportData.customs" id="customs">
            </div>

            <div class="checkbox-row">
                <div class="custom-checkbox">
                    <label for="call-before" class="master-label">
                        <input type="checkbox" id="call-before" v-model="this.transportData.call_before_planning"
                            ref="callCheckbox">
                        <span class="selector"></span>
                        Call before planning</label>
                </div>
                <div class="custom-checkbox">
                    <label for="attention-required" class="master-label">
                        <input type="checkbox" id="attention-required" v-model="this.transportData.attention_required"
                            ref="attentionRequired">
                        <span class="selector"></span>

                        Attention required</label>
                </div>
                <div class="custom-checkbox">
                    <label for="late-booking" class="master-label">
                        <input type="checkbox" id="late-booking" v-model="this.transportData.late_booking"
                            ref="lateBooking">
                        <span class="selector"></span>

                        Late booking</label>
                </div>
            </div>
        </div>
        <h3>Activities <span @click="addActivity">+</span></h3>
        <div v-for="activity in this.transportData.activities" :key="activity.transport_file_id" class="activities-inputs">
            <div class="input-label">
                <label for="id">ID</label>
                <input type="text" :value="activity.id" disabled>
            </div>
            <div class="input-label">
                <label for="type">Type</label>
                <select name="type" :id="'type' + activity.id">
                    <option :value="activity.activity_type">{{ activity.activity_type }}</option>
                    <option v-for="activityType in activityTypes" v-bind:key="activityType.IDActivity"
                        :value="activityType.IDActivity">{{
                            activityType.Description
                        }}</option>
                </select>
            </div>
            <div class="input-label">
                <label for="name">Address</label>
                <input type="text"
                    :value="activity.address.name + ', ' + activity.address.street_1 + ', ' + activity.address.zipcode + ', ' + activity.address.city + ', ' + activity.address.country"
                    :id="'activity-address' + activity.id" disabled class="address-input">
                <v-icon medium color="primary" @click="toggleExtendedAddress(activity)"
                    class="open-button-act">mdi-pencil-box</v-icon>
                <div class="main-div-extended" v-if="activity.showExtendedAddress">
                    <div class="inputs">
                        <div class="input-section">
                            <label for="field1">Name</label>
                            <input type="text" v-model="activity.address.name" :id="'activity-name' + activity.id">
                        </div>
                        <div class="input-section">
                            <label for="field2">Street</label>
                            <input type="text" v-model="activity.address.street_1"
                                :id="'activity-address-street1' + activity.id">
                        </div>
                        <div class="input-section">
                            <label for="field3">Zipcode</label>
                            <input type="text" v-model="activity.address.zipcode" :id="'activity-zip' + activity.id">
                        </div>
                        <div class="input-section">
                            <label for="field3">City</label>
                            <input type="text" v-model="activity.address.city" :id="'activity-city' + activity.id">
                        </div>
                        <div class="input-section">
                            <label for="field3">Country</label>
                            <input type="text" v-model="activity.address.country" :id="'activity-country' + activity.id">
                        </div>
                    </div>
                    <div class="buttons">
                        <v-btn color="error" dark @click="toggleExtendedAddress(activity)">
                            Cancel
                        </v-btn>
                        <v-btn color="success" dark @click="saveAddresExtended(activity)">
                            Save
                        </v-btn>
                    </div>
                </div>
            </div>
            <div class="input-label">
                <label for="date-period">Date period</label>
                <select name="date-period" :id="'date-period' + activity.id">
                    <option value="N/A">N/A</option>
                    <option v-for="datePeriod in datePeriods" v-bind:key="datePeriod.ID" :value="datePeriod.ID">
                        {{
                            datePeriod.Description
                        }}</option>
                </select>
            </div>
            <div class="input-label">
                <label for="date">Date</label>
                <select name="" :id="'activity-date' + activity.id">
                    <option v-for="date in activity.date" :key="date" :value="date">{{ date }}</option>
                    <option value="N/A">N/A</option>
                </select>
                <input type="date" :id="'activity-date' + activity.id + '-input'" class="custom-input">
                <v-icon medium color="primary" class="open-button-custom-act"
                    @click="toggleCustomInput('activity-date' + activity.id)">mdi-pencil-box</v-icon>
            </div>
            <div class="input-label">
                <label for="time-period">Time period</label>
                <select name="time-period" :id="'time-period' + activity.id">
                    <option :value="activity.time_prefix">{{ activity.time_prefix }}</option>
                    <option v-for="timePeriod in timePeriods" v-bind:key="timePeriod.ID" :value="timePeriod.ID">
                        {{
                            timePeriod.Description
                        }}</option>
                    <option value="N/A">N/A</option>
                </select>
            </div>
            <div class="input-label">
                <label for="time1">Time 1</label>
                <select name="" :id="'time1' + activity.id">
                    <option :value="activity.time_1">{{ activity.time_1[0] }}</option>
                    <option v-for="time1 in activity.time_1" :key="time1" :value="time1">{{ time1 }}
                    </option>
                    <option value="N/A">N/A</option>
                </select>
                <input type="time" :id="'time1' + activity.id + '-input'" class="custom-input">
                <v-icon medium color="primary" class="open-button-custom-act"
                    @click="toggleCustomInput('time1' + activity.id)">mdi-pencil-box</v-icon>
            </div>
            <div class="input-label">
                <label for="time2">Time 2</label>
                <select name="" :id="'time2' + activity.id">
                    <option :value="activity.time_2">{{ activity.time_2[0] }}</option>
                    <option v-for="time2 in activity.time_2" :key="time2" :value="time2">{{ time2 }}
                    </option>
                    <option value="N/A">N/A</option>
                </select>
                <input type="time" :id="'time2' + activity.id + '-input'" class="custom-input">
                <v-icon medium color="primary" class="open-button-custom-act"
                    @click="toggleCustomInput('time2' + activity.id)">mdi-pencil-box</v-icon>
            </div>
            <div class="input-label">
                <label for="reference">Reference</label>
                <select name="" :id="'activity-ref' + activity.id">
                    <option :value="activity.activity_reference[0]">{{ activity.activity_reference[0] }}
                    </option>
                    <option v-for="ref in activity.activity_reference" :key="ref" :value="ref">{{ ref }}
                    </option>
                    <option value="N/A">N/A</option>
                </select>
                <input type="text" :id="'activity-ref' + activity.id + '-input'" class="custom-input">
                <v-icon medium color="primary" class="open-button-custom-act"
                    @click="toggleCustomInput('activity-ref' + activity.id)">mdi-pencil-box</v-icon>
            </div>
            <div class="input-label">
                <v-btn color="error" dark @click="deleteActivity(activity)" small>
                    Delete
                </v-btn>
            </div>
        </div>
        <h3>Goods <span @click="addGood">+</span></h3>
        <div v-for="good in this.allGoods" :key="good.id" class="activities-inputs">
            <div class="input-label">
                <label for="act-id">Act ID</label>
                <select :id="'gactid' + good.id">
                    <option :value="good.activity_id">{{ good.activity_id }}</option>
                    <option v-for="id in transportData.activities" :value="id.id" :key="id.id">{{ id.id }}
                    </option>
                </select>
            </div>
            <div class="input-label">
                <label for="quantity">Quantity</label>
                <input type="text" v-model="good.quantity" :id="'gquantity' + good.id">
            </div>
            <div class="input-label">
                <label for="unit">Unit</label>
                <select name="" :id="'unit-type' + good.id">
                    <option v-for="unit in good.unit_type" :key="unit" :value="unit">{{ unit }}</option>
                    <option value="N/A">N/A</option>
                </select>
                <input type="text" :id="'unit-type' + good.id + '-input'" class="custom-input">
                <v-icon medium color="primary" class="open-button-custom-goods"
                    @click="toggleCustomInput('unit-type' + good.id)">mdi-pencil-box</v-icon>
            </div>
            <div class="input-label">
                <label for="gross-weight">Gross weight</label>
                <select name="" :id="'gweight' + good.id">
                    <option v-for="g in good.gross_weight" :key="g" :value="g">{{ g }}</option>
                    <option value="N/A">N/A</option>
                </select>
                <input type="text" :id="'gweight' + good.id + '-input'" class="custom-input">
                <v-icon medium color="primary" class="open-button-custom-goods"
                    @click="toggleCustomInput('gweight' + good.id)">mdi-pencil-box</v-icon>
            </div>
            <div class="input-label">
                <label for="nett-weight">Nett weight</label>
                <select name="" :id="'nweight' + good.id">
                    <option v-for="n in good.net_weight" :key="n" :value="n">{{ n }}</option>
                    <option value="N/A">N/A</option>
                </select>
                <input type="text" :id="'nweight' + good.id + '-input'" class="custom-input">
                <v-icon medium color="primary" class="open-button-custom-goods"
                    @click="toggleCustomInput('nweight' + good.id)">mdi-pencil-box</v-icon>
            </div>
            <div class="input-label">
                <label for="loading-meters">Ldm</label>
                <select name="" :id="'ldm' + good.id">
                    <option v-for="ldm in good.loading_meters" :key="ldm" :value="ldm">{{ ldm }}</option>
                    <option value="N/A">N/A</option>
                </select>
                <input type="text" :id="'ldm' + good.id + '-input'" class="custom-input">
                <v-icon medium color="primary" class="open-button-custom-goods"
                    @click="toggleCustomInput('ldm' + good.id)">mdi-pencil-box</v-icon>
            </div>
            <div class="input-label">
                <label for="length">Size</label>
                <select name="" :id="'size' + good.id">
                    <option v-for="size in good.size" :key="size" :value="size">{{ size }}</option>
                    <option value="N/A">N/A</option>
                </select>
                <input type="text" :id="'size' + good.id + '-input'" class="custom-input">
                <v-icon medium color="primary" class="open-button-custom-goods"
                    @click="toggleCustomInput('size' + good.id)">mdi-pencil-box</v-icon>
            </div>
            <div class="input-label">
                <v-btn color="error" dark @click="deleteGood(good)" small>
                    Delete
                </v-btn>
            </div>

        </div>
        <div calss="control-buttons">
            <v-btn color="success" dark @click="submitForm">
                Validate
            </v-btn>
            <v-btn color="error" dark class="ml-3" @click="cancelValidation">
                Cancel
            </v-btn>
            <v-btn color="primary" dark class="ml-3" @click="markForDevelopers">
                Mark for developers
            </v-btn>
        </div>
    </div>
</template>
<style scoped lang="scss">
input {
    border-bottom: 1px solid rgb(97, 97, 97);
}

.hidden {
    display: none;
}

.main-inputs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .input-label {

        margin-bottom: 5px;
        width: 50%;
        display: flex;
        justify-content: space-between;
        position: relative;

        label {
            font-weight: 500;
            font-size: 14px;
            margin-right: 15px;
        }

        input,
        select {
            margin-right: 20px;
            text-align: center;
            border: 1px solid rgb(214, 214, 214);
            border-radius: 5px;
            width: 200px;
            max-height: 26px;
        }

        select {
            margin-left: 10px;
            appearance: menulist;
            min-width: 200px;
        }


    }
}

.activities-inputs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;

    .input-label {
        position: relative;
        margin: 0 10px 5px 0;
        width: 27%;
        display: flex;
        justify-content: space-between;

        label {
            font-weight: 450;
            font-size: 14px;
            margin-right: 10px;
        }

        input,
        select {
            width: 110px;
            height: 20px;
            font-size: 12px;
            text-align: center;
            border: 1px solid rgb(214, 214, 214);
            border-radius: 5px;
        }

        input {
            min-width: 60px;
        }

        select {
            appearance: menulist;
        }
    }
}


h3 {
    margin-bottom: 20px;
    margin-top: 20px;
    color: white;
    background-color: var(--brand-color);
    border-radius: 5px;
    padding-left: 10px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;

    span {
        margin-right: 10px;
        cursor: pointer;
    }
}

.checkbox-row {
    display: flex;
    justify-content: space-around;
}

.custom-input {
    display: none;
}

.open-button {
    position: absolute;
    // transform: translate(130px, -1px);
    right: 0;
    transform: translateX(2px);
}

.open-button-custom-goods {
    position: absolute;
    right: 0;
    transform: translateX(25px);
}

.main-div-extended {
    width: 1000px;
    border: 1px solid grey;
    border-radius: 5px;
    position: absolute;
    left: -600px;
    // transform: translateY(-50px);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    z-index: 100;

    .inputs {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    .input-section {
        margin-top: 15px;

        input {
            margin-left: 15px;
            border: 1px solid rgb(211, 211, 211);
            border-radius: 5px;
        }
    }

    .buttons {
        display: flex;
        justify-content: center;
        margin: 10px 20px;

        .v-btn {
            margin-right: 10px;
        }
    }
}

.open-button-act {
    position: absolute;
    // transform: translate(210px, -2px);
    right: 0;
    transform: translateX(25px);
}

.open-button-custom-act {
    position: absolute;
    // transform: translate(210px, -2px);
    right: 0;
    transform: translateX(25px);
}
</style>
<script>
export default {
    data() {
        return {
            transportData: {},
            transport_order_id: "",
            allGoods: [],
            initialActivitiesCount: 0,
            initialGoodsCount: 0,
            countingGoodsCounter: 0,
            fileTypes: [
                {
                    "MD_Table": "FileType",
                    "ID": "Emballage",
                    "Description": "Emballage"
                },
                {
                    "MD_Table": "FileType",
                    "ID": "Transport Management",
                    "Description": "Transport Management"
                },
                {
                    "MD_Table": "FileType",
                    "ID": "Transport",
                    "Description": "Transport"
                }
            ],
            equipmentTypes: [
                {
                    "MD_Table": "EquipmentType",
                    "ID": "20FLATRACK",
                    "Description": "20 FT Flatrack\r\n"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "20FTCONTAI",
                    "Description": "20 FT Container"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "20FTCURTAI",
                    "Description": "20 FT CSC Container\r\n"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "40FTCONTAI",
                    "Description": "40 FT Container"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "40FTHCCONT",
                    "Description": "40 FT HC Container"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "40FTHCREEF",
                    "Description": "40 FT HC Reefer Container"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "40FTOTCONT",
                    "Description": "40 FT open top Container\r\n"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "40FTREEFCO",
                    "Description": "40 FT Reefer Container"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "40FTSILOCO",
                    "Description": "40 FT Silo Container"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "45FTCONTAI",
                    "Description": "45 FT Container"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "45FTCSC",
                    "Description": "45 FT CSC Container"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "45FTHCCONT",
                    "Description": "45 FT HC Container"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "45FTHCREEF",
                    "Description": "45 FT HC Reefer Container"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "45FTREEFCO",
                    "Description": "45 FT Reefer Container"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "45FTSWAPB",
                    "Description": "45 FT Swapbody"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "CONTAINER",
                    "Description": "Container"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "DIEP",
                    "Description": "Low loader"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "HT",
                    "Description": "Tilt/Taut"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "HTK",
                    "Description": "Tilt/Taut/Box"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "HUCKEPACK",
                    "Description": "Huckepack (13,6 ldm)"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "HUIF",
                    "Description": "Tilt"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "KAST",
                    "Description": "Box"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "LAADKLEP",
                    "Description": "Trailer with Taillift"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "LCL",
                    "Description": "LCL"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "MEGA",
                    "Description": "Megatrailer"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "MOTERWAGEN",
                    "Description": "7 ldm Truck with taillift"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "OPEN",
                    "Description": "Open / Flat Trailer"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "TAUT",
                    "Description": "Tautliner"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "TEMPERAT",
                    "Description": "Conditioned Truck"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "VOLUME",
                    "Description": "High volume trailer"
                },
                {
                    "MD_Table": "EquipmentType",
                    "ID": "ZWANEN",
                    "Description": "Semi low loader"
                },
            ],
            modalities: [
                {
                    "MD_Table": "Modality",
                    "ID": "Road",
                    "Description": "Road"
                },
                {
                    "MD_Table": "Modality",
                    "ID": "Train",
                    "Description": "Train"
                },
                {
                    "MD_Table": "Modality",
                    "ID": "Short sea",
                    "Description": "Short sea"
                },
                {
                    "MD_Table": "Modality",
                    "ID": "Inland shipping",
                    "Description": "Inland shipping"
                },
            ],
            serviceLevels: [
                {
                    "MD_Table": "Servicelevel",
                    "ID": "Direct",
                    "Description": "Direct"
                },
                {
                    "MD_Table": "Servicelevel",
                    "ID": "Neutral",
                    "Description": "Neutral"
                },
                {
                    "MD_Table": "Servicelevel",
                    "ID": "Regular",
                    "Description": "Regular"
                },
                {
                    "MD_Table": "Servicelevel",
                    "ID": "Urgent",
                    "Description": "Urgent"
                },
            ],
            incoterms: [
                {
                    "MD_Table": "Incoterms",
                    "ID": "Cost and Freight",
                    "Description": "Cost and Freight"
                },
                {
                    "MD_Table": "Incoterms",
                    "ID": "Cost, Insurance and Freight",
                    "Description": "Cost, Insurance and Freight"
                },
                {
                    "MD_Table": "Incoterms",
                    "ID": "Carriage and Insurance Paid To",
                    "Description": "Carriage and Insurance Paid To"
                },
                {
                    "MD_Table": "Incoterms",
                    "ID": "Carriage Paid To",
                    "Description": "Carriage Paid To"
                },
                {
                    "MD_Table": "Incoterms",
                    "ID": "Delivered At Place",
                    "Description": "Delivered At Place"
                },
                {
                    "MD_Table": "Incoterms",
                    "ID": "Delivered At Terminal",
                    "Description": "Delivered At Terminal"
                },
                {
                    "MD_Table": "Incoterms",
                    "ID": "Delivered Duty Paid",
                    "Description": "Delivered Duty Paid"
                },
                {
                    "MD_Table": "Incoterms",
                    "ID": "Ex Works",
                    "Description": "Ex Works"
                },
                {
                    "MD_Table": "Incoterms",
                    "ID": "Free Alongside Ship",
                    "Description": "Free Alongside Ship"
                },
                {
                    "MD_Table": "Incoterms",
                    "ID": "Free Carrier",
                    "Description": "Free Carrier"
                },
                {
                    "MD_Table": "Incoterms",
                    "ID": "Free On Board",
                    "Description": "Free On Board"
                },
            ],
            activityTypes: [
                {
                    "IDActivity": "Loading",
                    "Description": "Loading",
                    "DisplaySequence": 1,
                    "FirstActivityYN": 1,
                    "LastActivityYN": 0,
                    "GoodsMandatoryYN": 1
                },
                {
                    "IDActivity": "Delivery",
                    "Description": "Delivery",
                    "DisplaySequence": 2,
                    "FirstActivityYN": 0,
                    "LastActivityYN": 1,
                    "GoodsMandatoryYN": 1
                },
                {
                    "IDActivity": "Weigh",
                    "Description": "Weigh",
                    "DisplaySequence": 3,
                    "FirstActivityYN": 0,
                    "LastActivityYN": 0,
                    "GoodsMandatoryYN": 0
                },
                {
                    "IDActivity": "Customs",
                    "Description": "Customs",
                    "DisplaySequence": 4,
                    "FirstActivityYN": 0,
                    "LastActivityYN": 0,
                    "GoodsMandatoryYN": 0
                }
            ],
            datePeriods: [
                {
                    "ID": "fixed",
                    "Description": "Fixed date"
                },
                {
                    "ID": "before",
                    "Description": "Before date"
                },
            ],
            timePeriods: [
                {
                    "ID": "Before",
                    "Description": "Before"
                },
                {
                    "ID": "Between",
                    "Description": "Between"
                },
                {
                    "ID": "After",
                    "Description": "After"
                },
            ]
        };
    },
    mounted() {
        if (this.$route.params.transport_order_id == null) {
            console.log("error loading data");
            this.$router.push("orderlist");
        } else {
            this.transport_order_id = this.$route.params.transport_order_id;
            this.getTransportData();
            console.log(this.$route.params.transport_order_id);
        }
        window.scrollTo(0, 0);
    },
    methods: {
        getTransportData() {
            this.transportData = {};
            this.$local
                .get("/transport_files/" + this.transport_order_id)
                .then((res) => {
                    this.transportData = res.data;
                    console.log(res.data);
                    this.getActivities();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getActivities() {
            this.$local
                .get("/transport_files/" + this.transport_order_id + "/activities")
                .then((res) => {
                    this.$set(this.transportData, "activities", res.data)
                    console.log(res.data);
                    this.initialActivitiesCount = res.data.length;
                    console.log("Initial activities count: ", this.initialActivitiesCount)
                    this.transportData.activities.forEach(act => {
                        this.$set(act, "showExtendedAddress", false);
                    })
                    this.getGoods();
                })
                .catch((err) => {
                    console.log(err);
                });

        },
        getGoods() {

            this.transportData.activities.forEach(activity => {
                this.$local
                    .get("/activities/" + activity.id + "/goods")
                    .then((res) => {
                        let tempGoods = res.data;
                        tempGoods.forEach(good => {
                            this.allGoods.push(good);
                        });
                        console.log("GET GOODS RES DATA:", res.data.length, res.data)
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });

            // this.$set(this.transportData, "goods", this.allGoods)
            console.log("Initial goods count: ", this.initialGoodsCount, this.allGoods, "counter: ", this.countingGoodsCounter)
            console.log("Fille transport data: ", this.transportData)
            console.log("GOODS: ", this.allGoods)
        },
        cancelValidation() {
            this.$router.go(-1);
        },
        addActivity() {
            let emptyActivity = {
                "sequence_id": this.transportData.activities.length + 1,
                "showExtendedAddress": false,
                "activity_reference": [
                    ""
                ],
                "activity_type": "",
                "address_id": null,
                "date": [
                    ""
                ],
                "time_prefix": "",
                "time_1": [
                    ""
                ],
                "time_2": [
                    ""
                ],
                "instructions": "",
                "contact_id": 5,
                "id": 3,
                "address": {
                    "name": "",
                    "street_1": "",
                    "street_2": "",
                    "street_3": "",
                    "zipcode": "",
                    "city": "",
                    "country": "",
                    "latitude": 0,
                    "longitude": 0,
                    "id": 3,
                    "created_at": ""
                },
                "contact": {
                    "client_id": null,
                    "initials": "",
                    "name": "",
                    "surname_prefix": "",
                    "surname": "",
                    "phone": [""],
                    "mobile": [""],
                    "email": [""],
                    "id": 5,
                    "created_at": ""
                },
                "created_at": ""
            }
            this.transportData.activities.push(emptyActivity);

        },
        addGood() {
            this.countingGoodsCounter++;
            let tempObj = {
                id: this.countingGoodsCounter + "TEMPID"
            }
            this.allGoods.push(tempObj);

        },
        getSelectValue(id) {
            if (document.getElementById(id + '-input') && document.getElementById(id + '-input').value && document.getElementById(id + '-input').value != '') {
                return document.getElementById(id + '-input').value;
            } else {
                if (document.getElementById(id).options[document.getElementById(id).selectedIndex].value) {
                    return document.getElementById(id).options[document.getElementById(id).selectedIndex].value;
                } else {
                    return "N/A";
                }
            }
        },
        getInputValue(id) {
            if (document.getElementById(id).value == "" || !document.getElementById(id).value) {
                return 'N/A';
            }
            else return document.getElementById(id).value;
        },
        toggleCustomInput(id) {
            let select = document.getElementById(id);
            let input = document.getElementById(id + '-input');
            if (select.style.display != 'none') {
                select.style.display = 'none';
                input.style.display = 'block';
                input.value = this.getSelectValue(id);
            } else {
                select.style.display = '';
                input.style.display = 'none';
            }
        },
        submitForm() {
            //START general info
            let file_status = "progress";
            let inv_ref = this.getInputValue("inv-ref");
            let equipment_type = this.getInputValue("equipment");
            let file_type = this.getSelectValue("file-type");
            let modality = this.getSelectValue("modality");
            let service_level = this.getSelectValue("service-level");
            let incoterms = this.getSelectValue("incoterms");
            let depName = this.getInputValue("dep-name");
            let empName = this.getInputValue("employee");
            let customs = this.getInputValue("customs");
            let cost_code = this.getInputValue("costcode");
            let call, attention, late;
            if (this.$refs.callCheckbox.checked) {
                call = true;
            } else {
                call = false;
            }
            if (this.$refs.attentionRequired.checked) {
                attention = true;
            } else {
                attention = false;
            }
            if (this.$refs.lateBooking.checked) {
                late = true;
            } else {
                late = false;
            }

            let generalInfoObject = {
                "tr_file_status": file_status,
                "display_number": this.transportData.display_number,
                "invoice_reference": inv_ref,
                "file_type": file_type,
                "equipment_type": equipment_type,
                "modality": modality,
                "service_level": service_level,
                "customs": customs,
                "attention_required": attention,
                "multi_trip": true,
                "multi_activity": true,
                "date_deviation": true,
                "urgency": true,
                "late_booking": late,
                "cost_code": cost_code,
                "client_id": this.transportData.client.id,
                "contact_id": this.transportData.contact.id,
                "department_id": this.transportData.department.id,
                "employee_id": this.transportData.employee.id,
                "reported": true,
                "call_before_planning": call,
                "incoterms": incoterms,
                "certainty": 0,
                "reference": this.transportData.reference
            }
            console.log("call before: ", call)
            console.log("Attention required: ", attention)
            console.log("Late booking: ", late)
            this.$local.put("/transport_files/" + this.transportData.id,
                generalInfoObject
            ).then((res) => {
                console.log("RESPONSE OF PUT: ", res.data);
            })
            this.$local.put("/departments/" + this.transportData.department_id,
                {
                    "client_id": this.transportData.client_id,
                    "name": depName
                }
            ).then((res) => {
                console.log(res.data);
            })
            this.$local.put("/employees/" + this.transportData.employee_id,
                {
                    "client_id": this.transportData.client_id,
                    "name": empName
                }
            ).then((res) => {
                console.log(res.data);
            })
            // END General info
            //START Contact info
            let contact_email = this.getSelectValue("contact-email");
            console.log("CONTACT EMAIL : ", contact_email)
            let contact_phone;
            if (this.transportData.contact.phone[0]) {
                contact_phone = this.transportData.contact.phone[0];
            } else {
                contact_phone = "";
            }
            let contact_mobile;
            if (this.transportData.contact.mobile[0]) {
                contact_mobile = this.transportData.contact.mobile[0];
            } else {
                contact_mobile = "";
            }

            let contactObject = {
                "client_id": this.transportData.client_id,
                "initials": this.transportData.contact.initials,
                "name": this.transportData.contact.name,
                "surname_prefix": this.transportData.contact.surname_prefix,
                "surname": this.transportData.contact.surname,
                "phone": [
                    contact_phone
                ],
                "mobile": [
                    contact_mobile
                ],
                "email": [
                    contact_email
                ]
            }

            this.$local.put("/contacts/" + this.transportData.contact_id,
                contactObject
            ).then((res) => {
                console.log(res.data);
            })
            //END Contact info
            //START Client info
            let client_name = this.getSelectValue("client-name");

            let client_object = {
                "client_identifier": this.transportData.client.client_identifier,
                "name": [
                    client_name
                ]
            }

            this.$local.put("/clients/" + this.transportData.client_id,
                client_object
            ).then((res) => {
                console.log(res.data);
            })
            //END client info
            //START activities info
            for (let i = 0; i < this.initialActivitiesCount; i++) {
                let act = this.transportData.activities[i];
                let ref = this.getSelectValue('activity-ref' + act.id);
                let act_type = this.getSelectValue('type' + act.id);
                let date = this.getSelectValue('activity-date' + act.id);
                let time_prefix = this.getSelectValue('time-period' + act.id);
                let time1 = this.getSelectValue('time1' + act.id);
                let time2 = this.getSelectValue('time2' + act.id);

                console.log("TIME PREFIX: ", time_prefix)

                let activityObject = {
                    "transport_file_id": this.transportData.id,
                    "sequence_id": this.transportData.activities[i].sequence_id,
                    "activity_reference": [
                        ref
                    ],
                    "activity_type": act_type,
                    "address_id": act.address_id,
                    "date": [
                        date
                    ],
                    "time_prefix": time_prefix,
                    "time_1": [
                        time1
                    ],
                    "time_2": [
                        time2
                    ],
                    "instructions": act.instructions,
                    "contact_id": act.contact_id
                }
                this.$local.put("/activities/" + act.id,
                    activityObject
                ).then((res) => {
                    console.log(res.data);
                    // this.submitGoods();
                })
            }
            if (this.transportData.activities.length > this.initialActivitiesCount) {
                for (let i = this.initialActivitiesCount; i < this.transportData.activities.length; i++) {
                    let act = this.transportData.activities[i];
                    let ref = this.getSelectValue('activity-ref' + act.id);
                    let act_type = this.getSelectValue('type' + act.id);
                    let date = this.getSelectValue('activity-date' + act.id);
                    let time_prefix = this.getSelectValue('time-period' + act.id);
                    let time1 = this.getSelectValue('time1' + act.id);
                    let time2 = this.getSelectValue('time2' + act.id);

                    console.log("TIME PREFIX: ", time_prefix)

                    let activityObject = {
                        "transport_file_id": this.transportData.id,
                        "sequence_id": this.transportData.activities[i].sequence_id,
                        "activity_reference": [
                            ref
                        ],
                        "activity_type": act_type,
                        "address_id": this.transportData.activities[i].address_id,
                        "date": [
                            date
                        ],
                        "time_prefix": time_prefix,
                        "time_1": [
                            time1
                        ],
                        "time_2": [
                            time2
                        ],
                        "instructions": act.instructions,
                        "contact_id": act.contact_id
                    }

                    this.$local.post("/activities/",
                        activityObject
                    ).then((res) => {
                        console.log("ACTIVITIES POST:", res.data);
                        // this.submitGoods();
                    })
                }
            }
            //END activities info
            this.submitGoods();


            this.$router.go(-1);
        },

        markForDevelopers() {
            this.$local.post("/transport_files/" + this.transportData.id + "/report")
            window.alert("File has been marked for developers!")
        },
        toggleExtendedAddress(act) {
            if (!act.showExtendedAddress) {
                act.showExtendedAddress = false;
            }
            act.showExtendedAddress = !act.showExtendedAddress;
            console.log("ADDRESS", act, act.showExtendedAddress)
        },
        saveAddresExtended(act) {
            let address_name = this.getInputValue('activity-name' + act.id);
            let street = this.getInputValue('activity-address-street1' + act.id);
            let zip = this.getInputValue('activity-zip' + act.id);
            let city = this.getInputValue('activity-city' + act.id);
            let country = this.getInputValue('activity-country' + act.id);

            let addressObject = {
                "name": address_name,
                "street_1": street,
                "street_2": "",
                "street_3": "",
                "zipcode": zip,
                "city": city,
                "country": country,
                "latitude": 0,
                "longitude": 0
            }
            if (act.address_id) {
                this.$local.put("/addresses/" + act.address_id,
                    addressObject
                ).then((res) => {
                    act.showExtendedAddress = false;
                    console.log(res)
                })
            } else {
                this.$local.post("/addresses/",
                    addressObject
                ).then((res) => {
                    act.address_id = res.data.id;
                    act.showExtendedAddress = false;
                    console.log("New address ID= ", act.address_id)
                })
            }
        },
        deleteActivity(act) {
            if (this.transportData.activities.length > 1) {
                if (act.id > 10) {
                    this.$local.delete("/activities/" + act.id
                    ).then((res) => {
                        console.log(res)
                        this.$router.go()
                        this.getActivities();
                    })
                }
                else {
                    this.transportData.activities.pop();
                    this.$router.go;
                }
            }
            else {
                window.alert("You cannot leave the transport file with no activities")
            }

        },
        deleteGood(good) {
            if (this.allGoods.length > 1) {
                if (this.isANumber(good.id)) {
                    this.$local.delete("/goods/" + good.id
                    ).then(() => {
                        this.getGoods();
                    })
                }
                else {
                    this.allGoods.pop();
                }
            }
            else {
                window.alert("You cannot leave the transport file with no goods")
            }
        },
        isANumber(str) {
            return !/\D/.test(str);
        },
        submitGoods() {
            //START goods info

            this.allGoods.forEach(good => {

                let actid = this.getSelectValue("gactid" + good.id);
                let unit = this.getSelectValue("unit-type" + good.id);
                let quantity = this.getInputValue("gquantity" + good.id);
                // let description = this.getSelectValue("good-description" + good.id);
                let ldm = this.getSelectValue("ldm" + good.id);
                let nweight = this.getSelectValue("nweight" + good.id);
                let gweight = this.getSelectValue("gweight" + good.id);
                let size = this.getSelectValue("size" + good.id)

                let goodObj = {
                    "activity_id": actid,
                    "unit_type": [
                        unit
                    ],
                    "stackable": true,
                    "quantity": [
                        quantity
                    ],
                    "description": [
                        ""
                    ],
                    "loading_meters": [
                        ldm
                    ],
                    "net_weight": [
                        nweight
                    ],
                    "gross_weight": [
                        gweight
                    ],
                    "dangerous_goods": true,
                    "dg_class": "string",
                    "dg_product_group": "string",
                    "dg_un_code": "string",
                    "dg_technical_name": "string",
                    "size": [
                        size
                    ],
                    "volume_cbm": [
                        "string"
                    ]
                }
                if (this.isANumber(good.id)) {
                    this.$local.put("/goods/" + good.id,
                        goodObj
                    ).then((res) => {
                        console.log("PUT OF GOOD WITH ID: " + good.id + res.data);
                    })
                } else {
                    this.$local.post("/goods/",
                        goodObj
                    ).then((res) => {
                        console.log("POST OF NEW GOOD:" + res.data);
                    })
                }

            });
            // END goods info
        }
    },
};
</script>
