<template>
  <v-app>
    <v-row flex>
      <v-col class="navbarColumnResize">
        <Navigation />
      </v-col>
      <v-col class="mainBody" cols="12" sm="11" md="11" lg="11">
        <v-col>
            <div class="  d-flex flex-row">
                        <div class="d-flex flex-column">
                            <dialogComponentVue>
                                <v-btn large class="mx-2" fab icon>
                                    <v-icon> mdi-arrow-left </v-icon>
                                </v-btn>
                            </dialogComponentVue>
                        </div>
                        <div class=" d-flex flex-column ml-7 mt-1">
                            <h3>Transport Order #{{ OrderNumber }}</h3>
                        </div>
                        <v-spacer></v-spacer>
                        <div class="d-flex flex-row ">
                            <div class="d-flex flex-column">
                                <dialogComponentVue>
                                    <v-btn large class="mx-2" color="error" fab plain>
                                        cancel
                                    </v-btn>
                                </dialogComponentVue>
                            </div>
                            <v-spacer></v-spacer>
                            <div class="d-flex flex-column ml-7">
                                <v-btn color="success" rounded @click="goToCompletePlanning">
                                    complete Planning
                                    <v-icon right>
                                        mdi-check
                                    </v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </div>
        </v-col>
        <v-col>
          <v-card class="rounded-card" outlined elevation="2" height="auto">
            <v-toolbar height="18%" flat>
              <template>
                <v-tabs v-model="tab" align-with-title>
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab> Call History </v-tab>
                  <v-tab> Call Lists </v-tab>
                  <v-tab> Find Quotations </v-tab>
                  <v-tab> Phonebook </v-tab>
                  <v-tab> Call Log </v-tab>
                </v-tabs>
              </template>
            </v-toolbar>
            <div id="mymap" style="height: 500px"></div>
          </v-card>
        </v-col>
      </v-col>
    </v-row>
  </v-app>
</template>

<style>
.navbarColumnResize {
  max-width: 5%;
}

.topButtonBar {
  max-width: 50px !important;
}

.mainBody {
  padding-top: 25px;
}

.v-card {
  color: #fdfdfd;
  padding: 2%;
}
</style>
<script>
import Navigation from "../components/Navigation";
import dialogComponentVue from "../components/dialogComponent.vue";

export default {
  name: "ClusteringDrawingHexagon",
  components: {
    Navigation,
    dialogComponentVue
  },
  data: () => ({
    OrderNumber: 1254555,
  }),
  methods: {
    goToCompletePlanning() {
     var routerPush = this.$router.push("completeplanning");
      console.log(routerPush)
    },
  },
};

// var map = LMap('mymap');
// if (default_map_view != null) {
//     map.setView([default_map_view[0], default_map_view[1]], 11);
// }

// var layerCurrentTrip = new L.layerGroup().addTo(map);
// var startP = ""
// var endP = ""
// if ((current_trip_markers | safe) != null) {
//     for (marker in current_trip_markers) {
//         if (current_trip_markers.index(marker) == 0) {
//             L.marker(marker[0], marker[1]).bindPopup("Startpoint: {{ marker[2] |safe }}").addTo(layerCurrentTrip);
//             startP = "{{ marker[2] |safe }}";
//         }
//         // {s}, {z}, {x} and {y} are placeholders for map tiles
//         // {x} and {y} are the x/y of where you are on the map
//         // {z} is the zoom level
//         // {s} is the subdomain of cartodb
//         else {
//             L.marker(marker[0], marker[1]).bindPopup("Endpoint: {{ marker[2] |safe }}").addTo(layerCurrentTrip);
//             endP = "{{ marker[2] |safe }}";
//         }
//     }
//     console.log("start: " + startP);
//     console.log("end: " + endP);
// }
// if (Object.keys(direction_for_current_trip | safe).length == 0) {
//     console.log("Error");
// }
// else {
//     L.geoJSON(direction_for_current_trip | safe, { style: { "weight": 5 } }).bindPopup("Direction of the trip is to {{ current_trip_direction }}").on({
//         mousemove: function () {
//             this.openPopup();
//         },
//         mouseout: function () {
//             this.closePopup();
//         }
//     }).addTo(layerCurrentTrip);
// }
// var layerSimilarTrips = new L.LayerGroup();
// if (similar_trips_markers.length != 0) {
//     for (marker in similar_trips_markers)
//         for (mar in marker) {
//             for (ma in mar) {
//                 for (m in ma) {
//                     L.marker([m[0], m[1]]).on('mousemove', function (e) {
//                         console.log(e);
//                     }).addTo(layerSimilarTrips);
//                 }
//             }
//         }
// }

// var osmMapnik = new L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
//     attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
// }).addTo(map);
// var stadia_AlidadeSmooth = new L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png', {
//     attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
// });
// var stadia_AlidadeSmoothDark = new L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png', {
//     attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
// });
// if (directions_for_similar_trips | safe.length == 0) {
//     console.log("No similar routes")
// }
// else {
//     for (dir in directions_for_similar_trips) {
//         L.geoJSON(dir[0] | safe, { style: { "color": "red", "opacity": 0.65 } }).bindPopup("{{ dir[1] }}", { closeButton: false }).on('mousemove', function (e) {
//             console.log(e);
//             e.target.setStyle({ "color": "#ff5757", "opacity": 1.65, "weight": 5 });
//             e.target.openPopup();
//         }).on('mouseout', function (e) {
//             e.target.setStyle({ "color": "red", "opacity": 0.65, "weight": 2 });
//             e.target.closePopup();
//         }).addTo(layerSimilarTrips);
//     }
// }
// var baseMaps = {
//     "OpenStreetMap Mapnik": osmMapnik,
//     "Stadia Alidade Smooth": stadia_AlidadeSmooth,
//     "Stadia Alidade Smooth (Dark)": stadia_AlidadeSmoothDark,
// }
// var overlaysMap = {
//     "Current trip": layerCurrentTrip,
//     "Similar trips": layerSimilarTrips,
// };
// var layerControl = L.control.layers(baseMaps, overlaysMap).addTo(map);

// var editableLayers = new L.FeatureGroup();
// map.addLayer(editableLayers);

// // define custom marker
// var drawPluginOptions = {
//     position: 'topright',
//     draw: {
//         polyline: false,
//         polygon: {
//             allowIntersection: false, // Restricts shapes to simple polygons
//             drawError: {
//                 color: '#03cafc', // Color the shape will turn when intersects
//                 message: '<strong>Polygon draw does not allow intersections!<strong>' // Message that will show when intersect
//             },
//             shapeOptions: {
//                 color: '#0366fc'
//             }
//         },
//         circle: false, // Turns off this drawing tool
//         rectangle: {
//             shapeOptions: {
//                 clickable: false
//             }
//         },
//         marker: false,
//         circlemarker: false
//     },
//     edit: {
//         featureGroup: editableLayers, //REQUIRED!!
//         remove: false
//     }
// };

// // Initialise the draw control and pass it the FeatureGroup of editable layers
// var drawControl = new L.Control.Draw(drawPluginOptions);
// map.addControl(drawControl);

// var count = 0;
// polygons = [];
// var similar_trips = []

// for (t in similar_trips) {
//     var a = ['t["idTrip"]', ' t["carrierName"] ', ' t["loadpoint_Lat"] ', ' t["loadpoint_Long"] ', 't["delipoint_Lat"]', 't["delipoint_Long"]', 't["load_start_time"]', 't["load_end_time"]', 't["deli_start_time"]', 't["deli_end_time"]', 't["decode_waypoints"]', 't["trip_geojson"] |safe', 't["transport_costs"]', 't["total_costs"]', 't["date_start"]', 't["date_end"]', 't["compass_direction"]', 't["startpoint_address_type"]', 't["endpoint_address_type"]', 't["carrierID"]'];
// similar_trips.push(a);
// }
// console.log("aaa:" + similar_trips.length.toString())
// console.log(similar_trips);
// document.getElementById("buttonToShow").style.display = "none";
// map.on('draw:created', function (e) {
//     var type = e.layerType,
//         layer = e.layer;
//     var temp;
//     if (type === 'polygon') {

//         count += 1;
//         layer.bindPopup('Polygon ' + count.toString());
//         temp = layer.getLatLngs()[0];
//         console.log(temp);
//         var latLongPair = [];
//         temp.forEach(t => {
//             latLongPair = latLongPair.concat({ "lat": t.lat, "long": t.lng });
//         });
//         polygon = { "polygonNr": count - 1, "latLongs": latLongPair, "currentTripStartP": startP, "currentTripEndP": endP, "similar_trips": similar_trips };
//         polygons = polygons.concat(polygon);
//         console.log(temp);
//     }
//     editableLayers.addLayer(layer);
//     {
//         if (polygons.length > 0) {
//             document.getElementById("buttonToShow").style.display = "block";

//             $.ajax({
//                 url: '{{ url_for("get_trips_info") }}',
//                 type: "POST",
//                 contentType: "application/json",
//                 data: JSON.stringify(polygons),
//                 dataType: "json",
//                 success: function (response) {
//                     console.log("success response: ")
//                 },
//                 error: function (data) {
//                     document.getElementById("buttonToShow").style.display = "block";
//                     $("#buttonToShow").on("click", RefreshTable);

//                 }
//             });
//         }
//         console.log('polygon list length:' + polygons.length.toString());

//     }});
// function RefreshTable() {
//     $("#statisticsForCarriers").load('{{ url_for("get_trips_info") }} #statisticsForCarriers');
// }
// map.on('draw:edited', function (e) {
//     let layers = e.layers;
//     layers.eachLayer(function (layer) {
//         let polygonsEditLatLongs = new Array();

//         var latlongEditPolygon = layer.getLatLngs();
//         console.log("testoneEdit: " + latlongEditPolygon);
//         polygonsEditLatLongs.push(latlongEditPolygon);
//         ajax({
//             url: '{{ url_for("get_trips_info") }}',
//             type: "POST",
//             data: polygonsEditLatLongs,
//             success: loadMap(),
//             error: function (xhr) {
//                 console.log("error");
//             }
//         });

//         console.log("testPolyEdit: " + polygonsEditLatLongs);
//     });
// });
// function loadMap() {
//     ('#similarTripsTable').load('#similarTripsTable', function () {
//     ('#similarTripsTable').unwrap();
//     });
// }
</script>
