<template>
      <v-menu
        ref="menu"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="time"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            :label="timeState"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu2"
          format="24hr"
          v-model="time"
          full-width
          @click:minute="$refs.menu.save(time)"
        ></v-time-picker>
      </v-menu>
</template>

<script>
  export default {
    props:{
      timeState: {
        type: String,
        default: () => "Time From - To",
      },
      time: {
        type: String,
        default: () => "",
      }
    },
    data () {

      return {
        menu2: false,
        modal2: false,
      }
    },
    methods: {
    submit() {
      this.timeState = 'Something else'
    }
  },
  }
</script>