<template>

    <v-row>
        <div @click.stop="dialog = true">
            <slot/>
        </div>
        <v-dialog v-model="dialog" max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    Are you sure?
                </v-card-title>

                <v-card-text>
                    Going back will delete all the progress you have made.
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="gray darken-1" text @click="goBack">
                        Go back
                    </v-btn>

                    <v-btn color="green darken-1" text @click="dialog = false">
                        Stay
                    </v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>

export default {

    data() {
        return {
            dialog: false,
        }
    },
    methods: {
        goBack(){
this.$router.go(-1);
        },
    },

};
</script>