<template>
    <v-app>
        <v-row>
            <v-col class="navbarColumnResize">
                <Navigation />
            </v-col>
            <v-col>
                <h1>Notifications</h1>
                <div class="not-entry">
                    <div class="not-header" id="1">
                        <span>Contract no. 1234</span>
                        <v-icon large color="white" @click="toggleBody()" v-if="!showBody">mdi-chevron-down
                        </v-icon>
                        <v-icon large color="white" @click="toggleBody()" v-if="showBody">mdi-chevron-up
                        </v-icon>

                    </div>
                    <div class="not-body" v-if="showBody">
                        <div class="not-content">
                            <span>Contract proposal</span>
                            <span>From: user@mail.com</span>
                            <v-icon large color="black" @click="toggleDetails1()" v-if="!showDetails1">mdi-chevron-down</v-icon>
                            <v-icon large color="black" @click="toggleDetails1()" v-if="showDetails1">mdi-chevron-up</v-icon>
                        </div>
                        <div class="not-details" v-if="showDetails1">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec odio odio,
                                mattis
                                at
                                tincidunt quis, finibus id ante. Donec eu luctus mi. Maecenas porttitor
                                neque
                                metus,
                                a
                                mollis enim consequat vel. Phasellus luctus ante a tristique porttitor.
                                Morbi
                                non
                                ante
                                et justo laoreet convallis nec in ipsum. Mauris in nunc et odio fermentum
                                interdum.
                                Nullam vel euismod sapien. Sed maximus erat ac urna scelerisque pretium.
                                Donec
                                posuere,
                                diam at pulvinar accumsan, mauris turpis euismod diam, sed aliquet ante
                                velit
                                sed
                                lacus.
                                Duis consectetur leo quis felis finibus, sed scelerisque orci posuere.</p>
                            <v-icon large color="black">mdi-link</v-icon>
                        </div>
                        <div class="not-content">
                            <span>Contract proposal</span>
                            <span>From: user@mail.com</span>
                            <v-icon large color="black" @click="toggleDetails2()" v-if="!showDetails2">mdi-chevron-down</v-icon>
                            <v-icon large color="black" @click="toggleDetails2()" v-if="showDetails2">mdi-chevron-up</v-icon>
                        </div>
                        <div class="not-details" v-if="showDetails2">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec odio odio,
                                mattis
                                at
                                tincidunt quis, finibus id ante. Donec eu luctus mi. Maecenas porttitor
                                neque
                                metus,
                                a
                                mollis enim consequat vel. Phasellus luctus ante a tristique porttitor.
                                Morbi
                                non
                                ante
                                et justo laoreet convallis nec in ipsum. Mauris in nunc et odio fermentum
                                interdum.
                                Nullam vel euismod sapien. Sed maximus erat ac urna scelerisque pretium.
                                Donec
                                posuere,
                                diam at pulvinar accumsan, mauris turpis euismod diam, sed aliquet ante
                                velit
                                sed
                                lacus.
                                Duis consectetur leo quis felis finibus, sed scelerisque orci posuere.</p>
                            <v-icon large color="black">mdi-link</v-icon>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-app>
</template>

<script>
import Navigation from "../components/Navigation";

export default {
    name: "Notifications",
    components: {
        Navigation,
    },
    data() {
        return {
            showBody: false,
            showDetails1: false,
            showDetails2: false,
        }
    },
    methods: {
        toggleBody() {
            this.showBody = !this.showBody;
        },
        toggleDetails1() {
            this.showDetails1 = !this.showDetails1;
        },
        toggleDetails2() {
            this.showDetails2 = !this.showDetails2;
        }
    },
};
</script>

<style scoped lang="scss">
h1 {
    margin-top: 50px;
}

.not-entry {
    width: 95%;
}

.not-header {
    width: 100%;
    background-color: var(--brand-color);
    color: white;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    margin-top: 30px;

    span {
        font-size: 32px;
        margin-left: 45px;
    }

    .v-icon {
        margin-right: 45px;
    }
}

.not-body {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #A0A0A0;
    background-color: #E9E9E9;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}

.not-content {
    width: 90%;
    background-color: white;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 20px;
    border-radius: 5px;
    border: 1px solid #A0A0A0;
    height: 50px;
    font-size: 20px;
}

.not-details {
    width: 90%;
    border-radius: 5px;
    border: 1px solid #A0A0A0;
    background-color: white;
    margin-bottom: 20px;
    display: flex;
    padding: 20px;
}
</style>