<template>
  <section class="section-container">
    <v-row class="signin">
      <v-col cols="8" class="left"> </v-col>
      <v-col cols="4" class="right">
        <v-col md="10" offset-md="1" class="textLogo">
          <img src="../assets/logoWSlogan.svg" alt="Logichainge name, words only" />
        </v-col>
        <!-- <v-form @submit.prevent="submit">
          <v-text-field
            class="inputField"
            v-model="email"
            :rules="emailRules"
            :error-messages="errors"
            label="Email"
            required
          ></v-text-field>
          <v-text-field
            class="inputField"
            label="Password"
            v-model="password"
            :rules="[required, min6]"
            :error-messages="errors"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPass = !showPass"
            required
            :type="showPass ? 'text' : 'password'"
          ></v-text-field>
          <v-spacer></v-spacer>
          <div class="text-center">
              <v-btn class="signin-btn" type="submit" rounded @click="$router.push({ path: '/home' })">Sign In</v-btn>
          </div>
        </v-form> -->
        <div class="temp-div">
          <label for="email">Email</label>
          <input type="email" id="email">
          <label for="pass">Password</label>
          <input type="password" id="pass">
          <v-btn color="success" dark @click="this.tempLogin">
            Login
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<style scoped lang="scss">
.section-container {
  width: 100%;

  .signin {
    padding: 0;
    max-width: 100vw;
    margin: 0 auto;
    min-height: 100vh;
    box-shadow: 0 0 1px 1px rgba($color: #000000, $alpha: 0.1);

    .left {
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      display: flex;
      background-image: url("../assets/nigel-tadyanehondo-GOD2mDNujuU-unsplash-background-for-login.jpg");
      background-color: #fdfdfd;

    }

    .textLogo {
      width: 100vw;
      margin-bottom: 15%;
      height: auto;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      display: flex;
    }

    .right {
      justify-content: center;
      align-items: center;
      padding: 5%;
      box-sizing: border-box;
      background: white;
      color: #fdfdfd;

      h2 {
        text-align: center;
        margin: 30px 0;
      }
    }
  }
}
.temp-div {
  display: flex;
  flex-direction: column;
  label {
    color: black;
  }
  input {
    border-bottom: 1px solid rgb(214, 214, 214);
    margin-bottom: 20px;
  }
}
</style>

<script>
export default {
  name: "App",
  data: () => ({
    email: "",
    emailRules: [
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail must be valid",
    ],
    password: null,
    showPass: false,
  }),
  computed: {
    params() {
      return {
        email: this.email,
        password: this.password,
      };
    },
  },
  methods: {
    tempLogin() {
      let mail = document.getElementById("email").value;
      let password = document.getElementById("pass").value;
      console.log("MAIL: ", mail, " PASS: ", password);
      if(mail == "office@transvenlo.nl" && password == "transvenlo123") {
        this.$router.push("home");
      }
      else {
        window.alert("Credentials are invalid");
      }
    },
    required: function (value) {
      if (value) {
        return true;
      } else {
        return "This field is required.";
      }
    },
    min6: function (value) {
      if (value.length >= 6) {
        return true;
      } else {
        return "Password should have more than 6 characters.";
      }
    },
    //     async submit() {
    //   const valid = await this.$refs.observer.validate()
    //   if (valid) {
    //     this.login(this.params) // action to login
    //   }
    // },
    // clear() {
    //   // you can use this method to clear login form
    //   this.email = ''
    //   this.password = null
    //   this.$refs.observer.reset()
    // }
  },
};
</script>
