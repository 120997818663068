<template>
  <v-app>
    <Navigation />
    <h1>Settings</h1>
    <div class="main-body">
      <div class="body-info">
        <img src="../assets/user.png" alt="user avatar">
        <div class="text-fields">
          <div class="inputs">
            <label for="name">Name</label>
            <input type="text" id="name">
          </div>
          <div class="inputs">
            <label for="company">Company</label>
            <input type="text" id="company">
          </div>
          <div class="inputs">
            <label for="email">E-mail</label>
            <input type="email" id="email">
          </div>
        </div>
      </div>
      <div class="fields">
        <h2>Fields</h2>

        <v-row class="mt-3">
          <v-col>
            <v-switch v-model="ex11" label="File Type" color="primary" value="File type" hide-details></v-switch>
            <v-switch v-model="ex11" label="Main Modality" color="primary" value="Main modality" hide-details></v-switch>
            <v-switch v-model="ex11" label="Equipment Type" color="primary" value="Equipment type" hide-details></v-switch>
            <v-switch v-model="ex11" label="Service Level" color="primary" value="primary" hide-details></v-switch>
            <v-switch v-model="ex11" label="Address" color="primary" value="primary" hide-details></v-switch>
            <v-switch v-model="ex11" label="Postal Code" color="primary" value="primary" hide-details></v-switch>
            <v-switch v-model="ex11" label="City" color="primary" value="primary" hide-details></v-switch>
            <v-switch v-model="ex11" label="Country" color="primary" value="primary" hide-details></v-switch>
          </v-col>
          <v-col>
            <v-switch v-model="ex11" label="Unit" color="primary" value="primary" hide-details></v-switch>
            <v-switch v-model="ex11" label="Unit Description" color="primary" value="primary" hide-details></v-switch>
            <v-switch v-model="ex11" label="Description of Goods" color="primary" value="primary" hide-details></v-switch>
            <v-switch v-model="ex11" label="Gross Weight" color="primary" value="primary" hide-details></v-switch>
            <v-switch v-model="ex11" label="Nett Weight" color="primary" value="primary" hide-details></v-switch>
            <v-switch v-model="ex11" label="Loading Meters" color="primary" value="primary" hide-details></v-switch>
            <v-switch v-model="ex11" label="Width" color="primary" value="primary" hide-details></v-switch>
            <v-switch v-model="ex11" label="Height" color="primary" value="primary" hide-details></v-switch>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-app>
</template>

<style scoped lang="scss">
h1 {
  margin-left: 160px;
  margin-top: 70px;
  font-weight: 300;
}

.main-body {
  width: 80%;
  background-color: white;
  margin-left: 200px;
  margin-top: 50px;
}

.body-info {
  display: flex;
  align-items: center;
  margin-left: 20px;

  img {
    width: 80px;
    height: 80px;
    margin-right: 50px;
  }

  .text-fields {
    display: flex;
    flex-direction: column;

    input {
      border: 1px solid grey;
      border-radius: 4px;
      margin: 10px 0 0 20px;
      height: 30px;
      width: 250px;
    }

    .inputs {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.fields {
  margin: 30px 0 0 20px;

  h2 {
    border-bottom: 1px solid black;
    width: 90%;
  }

  .col {
    max-width: 300px;
  }
}
</style>

<script>
import Navigation from "../components/Navigation";
export default {
  name: "InProgress",
  components: {
    Navigation
  },
};
</script>
