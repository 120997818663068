<template>
  <v-app id="navigation">
      <v-navigation-drawer v-model="drawer" :mini-variant.sync="mini" permanent fixed color="primary">
        <v-list-item class="px-2">
          <v-list-item-avatar rounded>
            <img alt="Vue logo" max-width="100%" max-height="100%"  src="../assets/logo.svg">
          </v-list-item-avatar>

          <v-list-item-title   class="whiteLetters" >Logichainge</v-list-item-title>

          <v-btn color="white" icon @click.stop="mini = !mini">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>

        <v-divider color="white"></v-divider>

        <v-list>
          <v-list-item v-for="item in items" :key="item.title" link @click="$router.push({ path: item.router })">
            <v-list-item-icon>
              <v-icon color="white">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content >
              <v-list-item-title class="whiteLetters">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data () {
    return {
      drawer: true,
        items: [
          { title: 'Dashboard', router: 'home', icon: 'mdi-view-dashboard-outline' },
          { title: 'Orders', router: 'orderlist', icon: 'mdi-format-list-bulleted' },
          { title: 'Companies', router: 'companylist', icon: 'mdi-domain' },
          { title: 'Notifications', router: 'notifications', icon: 'mdi-bell-outline' },
          { title: 'Settings', router: 'settings', icon: 'mdi-cog-outline' },
          { title: 'Log out', router: '', icon: 'mdi-logout' },
          { title: 'Help', router: 'help', icon: 'mdi-help' },
        ],
        mini: true,
    }
  },
};
</script>

<style scoped>
.whiteLetters {
    color: white;
  }
.v-application {
  z-index: 5;
  position: absolute;
}

</style>
