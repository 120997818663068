<template>
  <v-app>
    <v-row>
      <v-col sm="1"
             md="1"
             lg="1">
        <Navigation />
      </v-col>
      <v-col class="joghurt"
             sm="10"
             md="10"
             lg="11">
        <v-row>
          <v-col>
            <div class="dragonFruit">
              ORDERS
            </div>
          </v-col>
        </v-row>
        <OrderNavigatorManual />
      </v-col>
    </v-row>
  </v-app>
</template>

<style>
.dragonFruit {
  position: absolute;
  top: 3vh;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: .1666666667em;
  color: gray;
}

.joghurt {
  padding-top: 10vh;
  padding-right: 10vh;
}
</style>
<script>
import Navigation from "../components/Navigation";
// import OrderNavigator from "../components/orderNavigator";
import OrderNavigatorManual from "../components/orderNavigatorManual.vue";

export default {
  name: "OrderList",
  components: {
    Navigation,
    // OrderNavigator,
    OrderNavigatorManual
},
};</script>