<template>
  <v-app>
      <Navigation/>
    <v-form id="dataValidationForm" ref="form" v-model="valid" lazy-validation>
      <v-col cols="12" sm="11" md="11" lg="12">
        <v-col>
          <div class="d-flex flex-row">
            <div class="d-flex flex-column">
              <dialogComponentVue>
                <v-btn large class="mx-2" fab icon>
                  <v-icon> mdi-arrow-left </v-icon>
                </v-btn>
              </dialogComponentVue>
            </div>
            <div class="d-flex flex-column ml-7 mt-1">
              <h3>Transport Order #{{ transport_order_id }}</h3>
            </div>
            <v-spacer></v-spacer>
          </div>
        </v-col>
        <v-row>
          <v-col>
            <v-container class="d-flex">
              <v-text-field
                class="inputField mr-3"
                label="Client Name"
                name="ClientName"
                id="ClientName"
                for="ClientName"
                v-model="this.loadedOrderInfo.client_name"
                :rules="mandatory"
                required
              ></v-text-field>
              <v-text-field
                class="clientContact"
                label="Client Contact"
                name="clientContact"
                id="clientContact"
                for="clientContact"
                v-model="this.loadedOrderInfo.contact_name"
                :rules="mandatory"
                required
              >
              </v-text-field>
            </v-container>
            <v-container class="d-flex">
              <v-text-field
                class="invoiceRef mr-3"
                label="Invoice Refrence"
                name="invoiceRef"
                id="invoiceRef"
                for="invoiceRef"
                v-model="this.loadedOrderInfo.invoice_reference"
                :rules="mandatory"
                required
              ></v-text-field>
              <v-select
                :items="fileTypes"
                label="File type"
                class="mr-3"
                v-model="this.loadedOrderInfo.file_type"
                :rules="mandatory"
              ></v-select>
            </v-container>
            <v-container class="d-flex">
              <v-select
                :items="modalities"
                label="Modality"
                class="mr-3"
              ></v-select>
              <v-select :items="serviceLevels" label="Service level"></v-select>
            </v-container>
          </v-col>
        </v-row>
        <v-btn depressed color="primary" @click="toggleActivities" class="d-none"> Add activity </v-btn>
        <v-btn depressed color="primary" @click="toggleActivities" class="d-none"> Add goods </v-btn>
        <div v-if="showActivities">
          <v-row>
            <v-col class="overflow-auto">
              <!-- this v-for loop goes through the activities and assigns the information to the fields.
            The key that is used is the id of the acitivity.
            -->
              <div
                v-for="activities in this.loadedOrderInfo.activities"
                :key="activities.id"
              >
                <v-item-group>
                  <v-divider></v-divider>
                  <v-subheader>
                    Client Activity {{ activities.id_sequence }} -
                    {{ activities.activity_type }}

                    <v-spacer></v-spacer>

                    <v-menu>
                      <!--Provides the three dot menu so that the user can add/Remove Activities/Goods 
          from the activity menu-->
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          v-for="(ActivityButtons, i) in ActivityButtons"
                          :key="i"
                        >
                          <v-btn
                            class="AddActivity"
                            :color="ActivityButtons.Color"
                            plain
                            small
                            >{{ ActivityButtons.title }}
                          </v-btn>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-subheader>
                  <v-container class="d-flex">
                    <v-select
                      label="Activity Type"
                      v-model="activities.activity_type"
                      :items="CAType"
                      dense
                      class="mr-3"
                    >
                    </v-select>
                    <v-text-field
                      class="CaAddress"
                      label="Address"
                      name="CaAddress"
                      id="CaAddress"
                      for="CaAddress"
                      :rules="mandatory"
                      v-model="activities.address.street_1"
                      required
                    ></v-text-field>
                  </v-container>
                  <v-container class="d-flex">
                    <v-text-field
                      class="PostCode"
                      label="Postal code"
                      name="PostCode"
                      id="PostCode"
                      for="PostCode"
                      v-model="activities.address.zipcode"
                      :rules="mandatory"
                      required
                    ></v-text-field>
                    <v-text-field
                      class="City mr-3"
                      label="City"
                      name="City"
                      id="City"
                      for="City"
                      v-model="activities.address.city"
                      :rules="mandatory"
                      required
                    ></v-text-field>
                    <v-text-field
                      class="Country mr-3"
                      label="Country"
                      name="Country"
                      id="Country"
                      for="Country"
                      v-model="activities.address.country"
                      :rules="mandatory"
                      required
                    ></v-text-field>
                    <v-text-field
                      class="RefrenceNumber"
                      label="Refrence Number"
                      name="RefrenceNumber"
                      id="RefrenceNumber"
                      for="RefrenceNumber"
                      v-model="RefrenceNumber"
                      required
                    ></v-text-field>
                  </v-container>
                  <v-container class="d-flex">
                    <DatePicker :date="activities.date" class="mr-3" />
                    <TimePicker
                      timeState="Time From"
                      :time="activities.time_1"
                      class="mr-3"
                    />
                    <TimePicker
                      timeState="Time Until"
                      :time="activities.time_2"
                    />
                  </v-container>
                  <v-textarea
                    name="driverInstructions"
                    id="driverInstructions"
                    class="DriverInstructions"
                    label="Driver Instructions"
                    v-model="activities.instructions"
                    outlined
                  ></v-textarea>
                </v-item-group>

                <!-- This section goes throughout the goods that are in the activity mentioned above 
              -->
                <div v-for="good in activities.goods" :key="good.id">
                  <v-item-group>
                    <v-divider></v-divider>
                    <v-subheader
                      >Goods {{ good.id }} to Client Activity
                      {{ good.activity_id }} - {{ activities.activity_type }}
                      <v-spacer></v-spacer>
                      <v-menu>
                        <!--Provides the three dot menu so that the user can add/Remove Activities/Goods 
          from the activity menu-->
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item
                            v-for="(ActivityButtons, i) in ActivityButtons"
                            :key="i"
                          >
                            <v-btn
                              class="AddActivity"
                              :color="ActivityButtons.Color"
                              plain
                              small
                              >{{ ActivityButtons.title }}
                            </v-btn>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-subheader>
                    <v-container class="d-flex">
                      <v-text-field
                        class="quantity mr-3"
                        label="Quantity"
                        name="quantity"
                        id="quantity"
                        for="quantity"
                        v-model="good.quantity"
                        :rules="mandatory"
                        required
                      ></v-text-field>
                      <v-text-field
                        class="unit"
                        label="Unit"
                        name="unit"
                        id="unit"
                        for="unit"
                        v-model="good.unit_type"
                        :rules="mandatory"
                        required
                      ></v-text-field> </v-container
                    >ƒ
                    <v-textarea
                      name="goodsDescription"
                      id="goodsDescription"
                      class="goodsDescription"
                      label="Description of Goods"
                      v-model="good.description"
                      outlined
                    ></v-textarea>
                    <v-container class="d-flex">
                      <v-text-field
                        class="grossWeight mr-3"
                        label="Gross Weight"
                        name="grossWeight"
                        id="grossWeight"
                        for="grossWeight"
                        v-model="good.gross_weight"
                        append-icon="mdi-weight-kilogram"
                        :rules="[numberRule]"
                      >
                      </v-text-field>
                      <v-text-field
                        class="NettWeight mr-3"
                        label="Nett Weight"
                        name="NettWeight"
                        id="NettWeight"
                        for="NettWeight"
                        v-model="good.net_weight"
                        append-icon="mdi-weight-kilogram"
                        :rules="[numberRule]"
                      >
                      </v-text-field>
                      <v-text-field
                        class="loadingMeters"
                        label="Loading Meters"
                        name="loadingMeters"
                        id="loadingMeters"
                        for="loadingMeters"
                        v-model="good.loading_meters"
                        append-icon="m"
                        :rules="[numberRule]"
                        required
                      >
                      </v-text-field>
                    </v-container>
                    <v-row class="measurementsOfGoods">
                      <v-col>
                        <v-text-field
                          class="goodsWidth"
                          label="Width"
                          name="goodsWidth"
                          id="goodsWidth"
                          for="goodsWidth"
                          v-model="goodsWidth"
                          append-icon="m"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          class="goodsHeight"
                          label="Height"
                          name="goodsHeight"
                          id="goodsHeight"
                          for="goodsHeight"
                          v-model="goodsHeight"
                          append-icon="m"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          class="goodsLength"
                          label="Length"
                          name="goodsLength"
                          id="goodsLength"
                          for="goodsLength"
                          v-model="goodsLength"
                          append-icon="m"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-checkbox
                          v-model="good.stackable"
                          label="Stackable"
                        ></v-checkbox>
                      </v-col>
                      <v-col>
                        <v-checkbox
                          v-model="good.dangerous_goods"
                          label="Dangerous Goods"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-item-group>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-col>
          <div class="d-flex flex-row">
            <v-spacer></v-spacer>
            <div class="d-flex flex-column">
              <dialogComponentVue>
                <v-btn large class="mx-2" color="error" fab plain>
                  cancel
                </v-btn>
              </dialogComponentVue>
            </div>
            <div class="d-flex flex-column ml-7">
              <v-btn color="warning" rounded disabled @click="reset">
                Reset Form
              </v-btn>
            </div>
            <div class="d-flex flex-column ml-7">
              <v-btn
                :disabled="!valid"
                color="success"
                rounded
                @click="validateFields"
              >
                Validate
                <v-icon right> mdi-check </v-icon>
              </v-btn>
            </div>
            <v-spacer></v-spacer>
          </div>
        </v-col>
      </v-col>
    </v-form>
  </v-app>
</template>
<style scoped lang="scss">
.v-subheader {
  color: orange;
}

.v-form {
  margin-left: 50px;
  max-width: 90%;
}
</style>
<script>
import DatePicker from "../components/InProgressComponents/DatePicker.vue";
import TimePicker from "../components/InProgressComponents/TimePicker.vue";
import dialogComponentVue from "../components/dialogComponent.vue";
import Navigation from '../components/Navigation.vue';

export default {
  name: "fileInfoCard",

  components: {
    DatePicker,
    TimePicker,
    dialogComponentVue,
    Navigation,
  },
  created() {
    window.addEventListener("beforeunload", function (event) {
      event.returnValue = "";
    });
  },

  methods: {
    toggleActivities() {
      this.showActivities = !this.showActivities;
    },

    //REQUIRED FOR THIS PAGE
    //prevents user from restarting page
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = "";
    },
    // submit the validation
    //checks the fields if they answer to the validation standards
    validateFields() {
      if (this.$refs.form.validate()) {
        this.submit();
        this.snackbar = true;
        console.log("form validated");
        this.$router.push("orderlist");
      } else {
        console.log("form failed to validate");
        alert("Please make sure all of the fields are filled in!");
      }
    },
    // reset the validation
    reset() {
      this.$refs.form.reset();
    },
    //takes the transport data from the api
    getTransportData() {
      this.transportData = [];
      this.loadedOrderInfo = {};
      this.$api
        .get("/transport_file/" + this.transport_order_id, {
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            "X-Authorization": this.$session.get("token"),
          },
        })
        .then((res) => {
          this.transportData = res.data;
          this.loadLoading();
          this.isLoaded = false;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //puts the json file to the backend. Doesn't work
    submit() {
      const URL =
        "https://logichange-backend-test.herokuapp.com/transport_file/" +
        this.transport_order_id;

      this.loadedOrderInfo.tr_file_status = "clustering";

      // PUT request using fetch with set headers
      this.$api
        .put(URL, this.loadedOrderInfo)
        .then(() => {
          JSON.stringify(this.loadedOrderInfo),
            console.log(this.loadedOrderInfo);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    //made for editing the incoming information
    loadLoading() {
      this.loadedOrderInfo = this.transportData;
    },
  },

  data() {
    return {
      fileTypes: ["PDF", "JPG", "PNG"],
      modalities: ["Road", "Freight", "Sea", "Train"],
      serviceLevels: ["Regular", "Priority", "Urgent"],
      showActivities: false,
      //General information and rules
      isEditing: false, //used to display popup when page is restarted
      transport_order_id: "",
      valid: true,
      mandatory: [(v) => !!v || "This field is mandatory"],
      numberRule: (v) => {
        if (!isNaN(parseFloat(v)) && v >= 0) return true;
        return "This field is numbers only";
      },

      //File Information
      defaultSelected: "",
      CAType: ["Load", "Deli"],
      CATyppeState: "",
      clientName: "",
      clientContact: "",
      invoiceRef: "",
      fileType: "Transport",
      toggle_exclusive: undefined,

      //Client Activity information
      ActivityButtons: [
        { title: "Add Activity", Color: "success", method: "" },
        { title: "Remove Activity", Color: "error", method: "" },
        { title: "Add Goods", Color: "success", method: "" },
        { title: "Remove Goods", Color: "error", method: "" },
      ],

      ActivityNumber: "",
      CaAddress: "",
      PostCode: "",
      City: "",
      Country: "",
      RefrenceNumber: "",
      format: "dddd MMMM Do, YYYY",
      date: "",
      driverInstr: {
        text: ``,
      },

      //Goods information
      GoodsNumber: "",
      quantity: "",
      unit: "",
      goodsDescription: {
        text: ``,
      },
      grossWeight: "",
      nettWeight: "",
      loadingMeters: "",
      goodsWidth: "",
      goodsHeight: "",
      goodsLength: "",
      checkboxStackable: false,
      checkboxADR: false,
      message: {
        text: ``,
      },
      submitted: false,

      // anIncreasingNumber: 1,
      // display_number: "",
      transportData: [],
      isLoaded: true,
      loadedOrderInfo: {},
      // end required for this page
    };
  },
};
</script>
