<template>
  <v-app>
    <v-row>
      <v-col class="navbarColumnResize">
        <Navigation />
      </v-col>
      <v-col cols="12" sm="11" md="11" lg="11">

        <v-row class="cherryBlossom">
          <!-- <v-col sm="1" md="3" lg="2">
            <ChartCircle />
          </v-col>
          <v-col sm="1" md="3" lg="2">
            <ChartCircle />
          </v-col> -->
          <v-col sm="1" md="3" lg="4">
            <SalesChart />
          </v-col>
          <v-col sm="1" md="3" lg="4">
            <ActiveUsers />
          </v-col>
        </v-row>
        <v-row>
          <v-col z-index="100">
            <!-- <OrderNavigator /> -->
            <orderNavigatorManual />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import Navigation from "../components/Navigation";
// import OrderNavigator from "../components/orderNavigator";
// import ChartCircle from "../components/ChartCircle.vue";
import SalesChart from "../components/salesChart.vue";
import ActiveUsers from "../components/ActiveUsers.vue";
// import pendingOrdersTableManual from "../components/OrderViewComponents/pendingOrdersTableManual.vue";
import orderNavigatorManual from "../components/orderNavigatorManual.vue";

export default {
  name: "Home",
  components: {
    Navigation,
    // OrderNavigator,
    // ChartCircle,
    SalesChart,
    ActiveUsers,
    // pendingOrdersTableManual,
    orderNavigatorManual
  },
};
</script>

<style scoped>
.navbarColumnResize{
  max-width: 5%;
}


.cherryBlossom {
  margin-top: 1%;
  margin-bottom: 100px;
}

.v-sheet.v-card {
  border-radius: 4px;
}

.col {
  height: 300px;
}
</style>
