<template>
  <v-app>
    <v-row flex>
      <v-col class="navbarColumnResize">
        <Navigation />
      </v-col>
      <v-col class="mainBody" cols="12" sm="11" md="11" lg="11">
        <v-col>
          <div class="d-flex flex-row">
            <div class="d-flex flex-column">
              <dialogComponentVue>
                <v-btn large class="mx-2" fab icon>
                  <v-icon> mdi-arrow-left </v-icon>
                </v-btn>
              </dialogComponentVue>
            </div>
            <div class="d-flex flex-column ml-7 mt-1">
              <h3>Transport Order #{{ OrderNumber }}</h3>
            </div>
            <v-spacer></v-spacer>
          </div>
        </v-col>
        <v-col>
          <v-card class="rounded-card" outlined elevation="2" height="auto">
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="4">
                  <v-card outlined>
                    <v-subheader>General</v-subheader>
                    <v-text-field
                      dense
                      class="tripNumber"
                      label="Trip Number"
                      name="triptNumber"
                      id="tripNumber"
                      v-model="tripNumber"
                      for="tripNumber"
                    ></v-text-field>
                    <v-text-field
                      dense
                      class="transportFile"
                      label="Transport File"
                      name="transportFile"
                      id="transportFile"
                      v-model="transportFile"
                      for="transportFile"
                    ></v-text-field>
                    <v-text-field
                      dense
                      class="haulier"
                      label="Haulier"
                      name="haulier"
                      id="haulier"
                      v-model="haulier"
                      for="haulier"
                    ></v-text-field>
                    <v-text-field
                      dense
                      class="contactPerson"
                      label="Contact Person"
                      name="contactPerson"
                      id="contactPerson"
                      v-model="contactPerson"
                      for="contactPerson"
                    ></v-text-field>
                    <v-text-field
                      dense
                      class="truckLP"
                      label="Truck License Plate"
                      name="truckLP"
                      id="truckLP"
                      v-model="truckLP"
                      for="truckLP"
                    ></v-text-field>
                    <v-text-field
                      dense
                      class="trailerLP"
                      label="Trailer License Plate"
                      name="trailerLP"
                      id="trailerLP"
                      v-model="trailerLP"
                      for="trailerLP"
                    >
                    </v-text-field>
                    <v-text-field
                      dense
                      class="driver"
                      label="Driver"
                      name="driver"
                      id="driver"
                      v-model="driver"
                      for="driver"
                    ></v-text-field>
                    <v-text-field
                      dense
                      class="soldBy"
                      label="Sold By"
                      name="soldBy"
                      id="soldBy"
                      v-model="soldBy"
                      for="soldBy"
                    ></v-text-field>
                    <v-text-field
                      dense
                      class="trasportOrderName"
                      label="Trasport Order Name"
                      name="trasportOrderName"
                      id="trasportOrderName"
                      v-model="trasportOrderName"
                      for="trasportOrderName"
                    ></v-text-field>
                    <v-select
                      dense
                      :items="reportLanguage"
                      v-model="selectedReportLangugage"
                      label="Report Language"
                    ></v-select>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="4">
                  <v-card outlined>
                    <v-subheader>Haulier Information</v-subheader>
                    <v-text-field
                      dense
                      readonly
                      class="haulierName"
                      label="Name"
                      name="haulierName"
                      id="haulierName"
                      v-model="haulierName"
                      for="haulierName"
                    ></v-text-field>
                    <v-text-field
                      dense
                      readonly
                      class="haulierStreet"
                      label="Street"
                      name="haulierStreet"
                      id="haulierStreet"
                      v-model="haulierStreet"
                      for="haulierStreet"
                    ></v-text-field>
                    <v-text-field
                      dense
                      readonly
                      class="haulierZipCode"
                      label="Zip Code"
                      name="haulierZipCode"
                      id="haulierZipCode"
                      v-model="haulierZipCode"
                      for="haulierZipCode"
                    ></v-text-field>
                    <v-text-field
                      dense
                      readonly
                      class="HaulierCity"
                      label="City"
                      name="HaulierCity"
                      id="HaulierCity"
                      v-model="HaulierCity"
                      for="HaulierCity"
                    ></v-text-field>
                    <v-text-field
                      dense
                      readonly
                      class="haulierCountry"
                      label="Country"
                      name="haulierCountry"
                      id="haulierCountry"
                      v-model="haulierCountry"
                      for="haulierCountry"
                    ></v-text-field>
                    <v-text-field
                      dense
                      readonly
                      class="haulierVatNumber"
                      label="haulierVatNumber"
                      name="haulierVatNumber"
                      id="haulierVatNumber"
                      v-model="haulierVatNumber"
                      for="haulierVatNumber"
                    >
                    </v-text-field>
                    <v-text-field
                      dense
                      readonly
                      class="haulierLanguage"
                      label="Language"
                      name="haulierLanguage"
                      id="haulierLanguage"
                      v-model="haulierLanguage"
                      for="haulierLanguage"
                    ></v-text-field>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="4">
                  <v-card outlined>
                    <v-subheader>Pallet Administration</v-subheader>
                    <v-text-field
                      dense
                      class="altPalletAddress"
                      label="Alternative Pallet Address"
                      name="altPalletAddress"
                      id="altPalletAddress"
                      v-model="altPalletAddress"
                      for="altPalletAddress"
                    >
                    </v-text-field>
                    <v-text-field
                      dense
                      class="altPalletClient"
                      label="Alternative Pallet Client"
                      name="altPalletClient"
                      id="altPalletClient"
                      v-model="altPalletClient"
                      for="altPalletClient"
                    >
                    </v-text-field>
                    <v-checkbox
                      dense
                      v-model="exchangeOfPallets"
                      label="No exchange of pallets agreed"
                    ></v-checkbox>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="6">
                  <v-card outlined>
                    <v-subheader>Agreed Transport Costs</v-subheader>
                    <v-col>
                      <v-row>
                        <v-text-field
                          dense
                          readonly
                          class="crCode"
                          label="C/R Code"
                          name="crCode"
                          id="crCode"
                          v-model="crCode"
                          for="crCode"
                        >
                        </v-text-field>
                        <v-spacer></v-spacer>
                        <v-text-field
                          dense
                          readonly
                          class="freightCharges"
                          label="Freight Charges"
                          name="freightCharges"
                          id="freightCharges"
                          v-model="freightCharges"
                          for="freightCharges"
                        ></v-text-field>
                        <v-spacer></v-spacer>
                        <v-text-field
                          dense
                          readonly
                          class="transportCosts"
                          label="Costs"
                          name="transportCosts"
                          id="transportCosts"
                          v-model="transportCosts"
                          for="transportCosts"
                        ></v-text-field>
                        <v-spacer></v-spacer>
                        <v-text-field
                          dense
                          readonly
                          class="transportCurrency"
                          label="Currency"
                          name="transportCurrency"
                          id="transportCurrency"
                          v-model="transportCurrency"
                          for="transportCurrency"
                        >
                        </v-text-field>
                      </v-row>
                    </v-col>
                    <v-textarea
                      dense
                      name="remarks"
                      id="remarks"
                      class="remarks"
                      label="Remarks"
                      v-model="remarks.text"
                      outlined
                    ></v-textarea>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="6">
                  <v-card outlined>
                    <v-subheader>Other Costs</v-subheader>
                    <v-data-table
                      :headers="headersOtherCosts"
                      :items="otherCosts"
                      :items-per-page="5"
                    ></v-data-table>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-card outlined>
                    <v-subheader>Activities</v-subheader>
                    <v-data-table
                      :headers="headersActivities"
                      :items="activities"
                      :items-per-page="5"
                    ></v-data-table>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-card outlined>
                    <v-subheader>Instructions</v-subheader>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" lg="6">
                        <v-data-table
                          :headers="headersInstructions"
                          :items="instructions"
                          :items-per-page="5"
                        ></v-data-table>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="6">
                        <v-textarea
                          dense
                          name="instructionHaulier"
                          id="instructionHaulier"
                          class="instructionHaulier"
                          label="Instructions for Haulier"
                          v-model="instructionHaulier.text"
                          outlined
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col>
          <div class="d-flex flex-row">
            <v-spacer></v-spacer>
            <div class="d-flex flex-column">
              <dialogComponentVue>
                <v-btn large class="mx-2" color="error" fab plain>
                  cancel
                </v-btn>
              </dialogComponentVue>
            </div>
            <div class="d-flex flex-column ml-7">
              <v-btn color="success" rounded @click="goToOrderList">
                Plan Haulier Trip
                <v-icon right> mdi-check </v-icon>
              </v-btn>
            </div>
            <v-spacer></v-spacer>
          </div>
        </v-col>
      </v-col>
    </v-row>
    <v-row> </v-row>
  </v-app>
</template>

<style>
.navbarColumnResize {
  max-width: 125px;
}
body {
  margin-bottom: 300px;
}
</style>
<script>
import Navigation from "../components/Navigation";
import dialogComponentVue from "../components/dialogComponent.vue";

export default {
  name: "ClusteringDrawingHexagon",
  components: {
    Navigation,
    dialogComponentVue,
  },
  data: () => ({
    OrderNumber: 1254555,

    //General Data Section
    tripNumber: "",
    transportFile: "",
    haulier: "",
    contactPerson: "",
    truckLP: "",
    trailerLP: "",
    driver: "",
    soldBy: "",
    trasportOrderName: "",
    reportLanguage: ["Dutch", "English", "French", "German"],
    selectedReportLangugage: "Dutch",

    //Haulier Information Data Section
    haulierName: "",
    haulierStreet: "",
    haulierZipCode: "",
    HaulierCity: "",
    haulierCountry: "",
    haulierVatNumber: "",
    haulierLanguage: "",

    //Palet Administration Data Section
    altPalletAddress: "",
    altPalletClient: "",
    exchangeOfPallets: false,

    //Agreed Transport Cost Data Section
    crCode: "",
    freightCharges: "",
    transportCosts: "",
    transportCurrency: "",
    remarks: {
      text: ``,
    },

    //Other Costs Data Section
    headersOtherCosts: [
      { text: "Code", value: "null" },
      { text: "C/R Type", value: "null" },
      { text: "Amount", value: "null" },
      { text: "Unit", value: "null" },
      { text: "Price", value: "null" },
      { text: "Total", value: "null" },
      { text: "Currency", value: "null" },
    ],

    // Activities Data Section
    headersActivities: [
      { text: "Type", value: "null" },
      { text: "Name", value: "null" },
      { text: "Address", value: "null" },
      { text: "Unit", value: "null" },
      { text: "Zip Code", value: "null" },
      { text: "City", value: "null" },
      { text: "Country", value: "null" },
      { text: "Date per", value: "null" },
      { text: "Date", value: "null" },
      { text: "Time per", value: "null" },
      { text: "Time", value: "null" },
      { text: "Time", value: "null" },
      { text: "Refrence", value: "null" },
      { text: "ETA", value: "null" },
    ],

    //Instructions Data Section
    headersInstructions: [
      { text: "Missing Translation", value: '"null"' },
      { text: "Source", value: "null" },
      { text: "Description", value: "null" },
      { text: "Transport Order", value: "null" },
      { text: "Internal", value: "null" },
      { text: "Pop-up", value: "null" },
    ],
    instructionHaulier: {
      text: ``,
    },
  }),
    methods: {
    goToOrderList() {
     var routerPush = this.$router.push("orderlist");
      console.log(routerPush)
    },
  },
};
</script>
