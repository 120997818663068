<template>
  <v-app>
    <Navigation />
  </v-app>
</template>

<script>
import Navigation from "../components/Navigation";
export default {
  name: "InProgress",
  components: {
    Navigation
  },
};
</script>
