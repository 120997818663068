import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import store from './store'
import VueSession from 'vue-session'
import vuetify from './plugins/vuetify'
import router from './router'
import "./assets/styles.scss"

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import LDrawToolbar from 'vue2-leaflet-draw-toolbar';
Vue.component('l-draw-toolbar', LDrawToolbar);

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.config.productionTip = false;
Vue.use(VueSession)
Vue.config.silent = true;

let api = axios.create({
    baseURL: 'https://logichainge-backend-26ar3gp3ja-ez.a.run.app'
})

let local = axios.create({
    baseURL: 'https://logichainge-backend-github-26ar3gp3ja-ez.a.run.app'
    // baseURL: 'http://192.168.1.235:80'
})

let allo = axios.create({
    baseURL: 'https://logichainge-allocation.ew.r.appspot.com'
})
let uploadUrl = axios.create({
    baseURL: 'https://a60c-2a02-a58-814c-c00-f9e2-1d2d-a3c8-9322.eu.ngrok.io'
})

Vue.prototype.$api = api
Vue.prototype.$allo = allo
Vue.prototype.$local = local
Vue.prototype.$uploadUrl = uploadUrl

new Vue({
    store,
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app')