<template>
    <div>
        <div class="tabs">
            <div class="tab-focused" v-if="currentTab == 'pending'" @click="clickPending">
                Pending
            </div>
            <div class="tab" v-if="currentTab !== 'pending'" @click="clickPending">
                Pending
            </div>
            <div class="tab-focused" v-if="currentTab == 'progress'" @click="clickProgress">
                In progress
            </div>
            <div class="tab" v-if="currentTab !== 'progress'" @click="clickProgress">
                In progress
            </div>
            <div class="tab" @click="clickWip">
                Clustering
            </div>
            <div class="tab" @click="clickWip">
                Allocating
            </div>
            <div class="tab" @click="clickWip">
                Tracking
            </div>
            <div class="tab" @click="clickWip">
                Completed
            </div>
        </div>
        <pendingOrdersTableManual v-if="currentTab == 'pending'"/>
        <readyForPlanningTableManual v-if="currentTab == 'progress'"/>
    </div>
</template>
<style scoped>
.tabs {
    display: flex;
    margin: 20px 0 15px 0;
}

.tab-focused {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--brand-color);
    border-radius: 5px;
    margin-right: 5px;
    color: white;
    padding: 5px 10px 5px 10px;
    transition: all 0.5s 0s ease-in-out;
    cursor: pointer;

}

.tab:hover {
    color: white;
    background-color:  var(--brand-color);
    border: 1px solid;
}

.tab {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    margin-right: 5px;
    color: var(--brand-color);
    border-color: var(--brand-color);
    border: 1px solid;
    padding: 5px 10px 5px 10px;
    cursor: pointer;
    transition: all 0.5s 0s ease-in-out;
}
</style>
<script>
import pendingOrdersTableManual from './OrderViewComponents/pendingOrdersTableManual.vue';
import readyForPlanningTableManual from './OrderViewComponents/readyForPlanningTableManual.vue';
export default {
    data() {
        return {
            currentTab: 'pending'
        }
    },
    components: {
        pendingOrdersTableManual,
        readyForPlanningTableManual
    },
    methods: {
        clickPending() {
            this.currentTab = 'pending';
        },
        clickProgress() {
            this.currentTab = 'progress';
        },
        clickWip() {
            alert("This tab is not yet implemented");
        }

    },

};
</script>